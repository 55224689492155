import React, { PureComponent } from 'react'

import injectSheet from 'react-jss'

// import paypalImg from 'img/vectores/paypal.svg'
import visaImg from 'img/vectores/Visa.svg'
import mcImg from 'img/vectores/MasterCard.svg'
import aeImg from 'img/vectores/AmericanExpress.svg'
// import paypalImg from 'img/vectores/paypal.svg'
import { Container, Row, Col } from 'reactstrap'
import { PaymentBannerStyle, PaymentBannerProps } from './types'

const styles: PaymentBannerStyle = () => ({
  root: {}
})

const paymentImgs = [visaImg, mcImg, aeImg]

const paymentIcon = (img: string, i: number) => (
  <Col sm={2} xs={3} key={i}>
    <img src={img} />
  </Col>
)

class PaymentBanner extends PureComponent<PaymentBannerProps> {
  render() {
    let { classes } = this.props

    classes = classes || {}

    return (
      <Container className="pad text-center pagos">
        <Row>
          <Col sm={3} xs={12}>
            <h6>
              Compra segura <i className="fas fa-lock" />
            </h6>
          </Col>
          {paymentImgs.map(paymentIcon)}
        </Row>
      </Container>
    )
  }
}

export default injectSheet(styles)(PaymentBanner)
