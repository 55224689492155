import { withFormsy } from 'formsy-react'
import React, { Component } from 'react'
import AFormatError from '../../common/AFormatError/AFormatError'
import AFormatLabel from '../../common/AFormatLabel/AFormatLabel'
import ARadioGroup from '../../common/ARadioGroup/ARadioGroup'
import { RadioFormsyProps } from './types'

class RadioFormsy extends Component<RadioFormsyProps> {
  changeValue = (_event: any, value: any) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.props.setValue(value)
  }

  render() {
    const { radioProps, labelClassName, children } = this.props
    const { label } = this.props
    const errorMessage = this.props.getErrorMessage()
    const value = this.props.getValue() || ''
    const RadioClassName = errorMessage ? 'formsy-error' : ''
    let required
    if (label && this.props.showRequired()) {
      required = <span className="text-danger"> *</span>
    }
    return (
      <div className="form-group">
        <AFormatLabel className={labelClassName}>
          {label} {required}
        </AFormatLabel>
        <ARadioGroup value={value} className={RadioClassName} onChange={this.changeValue} {...radioProps} children={children} />
        {errorMessage ? <AFormatError helperText={errorMessage} /> : null}
      </div>
    )
  }
}

export default withFormsy(RadioFormsy)
