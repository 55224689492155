import { withFormsy } from 'formsy-react'
import React, { Component } from 'react'

import { CheckboxFormsyProps } from './types'
import ACheckbox from '../../common/ACheckbox/ACheckbox'
import AFormatError from '../../common/AFormatError/AFormatError'

class CheckboxFormsy extends Component<CheckboxFormsyProps> {
  changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.checked
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.props.setValue(value)
  }

  render() {
    const { checkboxProps, label, labelClassName } = this.props
    const errorMessage = this.props.getErrorMessage()
    const value = this.props.getValue() || ''
    const CheckboxClassName = errorMessage ? 'formsy-error' : ''
    return (
      <div className="form-group">
        <ACheckbox
          value={value}
          className={CheckboxClassName}
          label={label}
          labelClassName={labelClassName}
          onChange={this.changeValue}
          {...checkboxProps}
        />
        {errorMessage ? <AFormatError helperText={errorMessage} /> : null}
      </div>
    )
  }
}

export default withFormsy(CheckboxFormsy)
