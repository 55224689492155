import React, { Component } from 'react'
import AsyncSelect from 'react-select/lib/Async'
import _ from 'lodash'
import { SweetSelectProps, SweetSelectState } from './types'
import { QueryParams } from 'core/services/GenericService/types'
import { GenericService } from 'core/services'
import { ValueType } from 'react-select/lib/types'
import classNames from 'classnames'
import { isExisty } from 'core/utils'

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #b0afb5',
    minHeight: 50
  })
}

export class SweetSelect<OptionType = any> extends Component<SweetSelectProps<OptionType>, SweetSelectState<OptionType>> {
  static defaultProps = {
    placeholder: 'Buscar...',
    loadingMessage: () => 'Cargando...',
    noOptionsMessage: (inputValue: string) => (!inputValue ? 'Buscar...' : 'No hay resultados')
  }

  static getDerivedStateFromProps(nextProps: SweetSelectProps<any>, prevState: SweetSelectState<any>) {
    if (nextProps.value === prevState.value) {
      return null
    }
    return { value: nextProps.value }
  }

  state: SweetSelectState<OptionType> = {}

  constructor(props: SweetSelectProps<OptionType>) {
    super(props)
    this.fetchOptions = _.debounce(this.fetchOptions, 500)
  }

  fetchOptions = (inputValue: string, cb: (items: OptionType[]) => void) => {
    if (!isExisty(inputValue)) {
      return cb([])
    }
    const { limit, fetchQuery, searchKeys, model } = this.props

    const options: QueryParams = {
      searchKeys,
      to: limit || 5,
      from: 0,
      search: inputValue,
      ...fetchQuery
    }

    if (!model) {
      return cb([])
    }

    GenericService.list<OptionType[]>(model, options)
      .then(result => {
        if (result.length) {
          this.handleSuccess(result)
          return cb(result)
        }
      })
      .catch(this.handleError)
  }

  handleError = (err: any) => {
    const { onFetchError } = this.props
    if (onFetchError) {
      onFetchError(err)
    }
  }

  handleSuccess = (data: OptionType[]) => {
    const { onFetchSuccess } = this.props
    if (onFetchSuccess) {
      onFetchSuccess(data)
    }
  }

  handleChange = (selectedOption: ValueType<OptionType>) => {
    if (this.props.onChange) {
      this.props.onChange(selectedOption)
    }
  }

  render() {
    const importedProps = _.pick(this.props, [
      'placeholder',
      'getOptionLabel',
      'getOptionValue',
      'isMulti',
      'isClearable',
      'isDisabled',
      'cacheOptions',
      'loadingMessage',
      'noOptionsMessage',
      'defaultOptions',
      'defaultValue'
    ])
    const { value } = this.state
    return (
      <AsyncSelect<OptionType>
        value={value}
        className={classNames(this.props.className, 'react-select')}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          borderRadius: 0
        })}
        loadOptions={this.fetchOptions}
        onChange={this.handleChange}
        {...importedProps}
      />
    )
  }
}

export default SweetSelect
