import { withFormsy } from 'formsy-react'
import React, { Component } from 'react'
import AFormatError from '../../common/AFormatError/AFormatError'
import AFormatLabel from '../../common/AFormatLabel/AFormatLabel'
import ASelect from '../../common/ASelect/ASelect'
import { guid } from 'core/utils'
import { SelectFormsyProps } from './type'

class SelectFormsy extends Component<SelectFormsyProps> {
  id = guid()

  changeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.props.setValue(value)
  }

  render() {
    const { selectProps, children, labelClassName } = this.props
    const { label } = this.props
    const errorMessage = this.props.getErrorMessage()
    const value = this.props.getValue() || ''
    const selectClassName = errorMessage ? 'formsy-error' : ''
    let required
    if (label && this.props.showRequired()) {
      required = <span className="text-danger"> *</span>
    }
    return (
      <div className="form-group">
        {label ? (
          <AFormatLabel htmlFor={this.id} className={labelClassName}>
            {label} {required}
          </AFormatLabel>
        ) : null}
        <ASelect id={this.id} value={value} className={selectClassName} onChange={this.changeValue} {...selectProps}>
          {children}
        </ASelect>
        {errorMessage ? <AFormatError helperText={errorMessage} /> : null}
      </div>
    )
  }
}

export default withFormsy(SelectFormsy)
