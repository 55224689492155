import React, { Component, SFC } from 'react'
import { SweetProgressProps, ProgressStep } from './types'
import classNames from 'classnames'

import 'styles/sweet-progress.css'

const ProgressItem: SFC<ProgressStep> = ({ content, description, isComplete }) => (
  <li className={classNames('step', { 'is-complete': isComplete })}>
    <span className="content">{content}</span>
    <span className="description">{description}</span>
  </li>
)

export class SweetProgress extends Component<SweetProgressProps> {
  render() {
    const { steps, current } = this.props

    return (
      <ol className="SweetProgress">
        {steps.map((s, i) => (
          <ProgressItem key={i} {...s} isComplete={i < current} />
        ))}
      </ol>
    )
  }
}

export default SweetProgress
