import { withFormsy } from 'formsy-react'
import React, { Component } from 'react'
import { guid, validMomentValue } from 'core/utils'
import { DatepickerFormsyProps, DatepickerFormsyState } from './types'
import { Moment } from 'moment'
import AFormatError from '../../common/AFormatError/AFormatError'
import AFormatLabel from '../../common/AFormatLabel/AFormatLabel'
import ADatepicker from '../../common/ADatepicker/ADatepicker'

class DatepickerFormsy extends Component<DatepickerFormsyProps, DatepickerFormsyState> {
  state: DatepickerFormsyState = {}
  id = guid()

  updateInputValue = (value: Moment | null) => {
    this.setState({
      inputValue: (value && value.format('L')) || ''
    })
  }

  changeValue = (value: Moment | null) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.updateInputValue(value)
    this.props.setValue(value)
  }

  handleError = (val: Moment, err: string) => {
    if (this.props.pickerProps) {
      const { minDate, maxDate } = this.props.pickerProps
      if (minDate && maxDate) {
        if (err === 'MIN_DATE_ERROR' || err === 'MAX_DATE_ERROR') {
          const value = validMomentValue(val, minDate, maxDate)
          this.setState({
            inputValue: value.format('L')
          })
          this.changeValue(value)
        }
      }
    }

    if (err === 'INVALID_DATE') {
      this.setState({ inputValue: '' })
      this.changeValue(null)
    }
  }

  handleInput = (val: string) => {
    this.setState({ inputValue: val })
  }

  render(): React.ReactNode {
    const { pickerProps, labelClassName } = this.props
    const { label } = this.props
    const errorMessage = this.props.getErrorMessage()
    const value = this.props.getValue() || null
    const inputClassName = errorMessage ? 'formsy-error' : ''
    let required
    if (label && this.props.showRequired()) {
      required = <span className="text-danger"> *</span>
    }
    return (
      <div className="form-group">
        <AFormatLabel htmlFor={this.id} className={labelClassName}>
          {label} {required}
        </AFormatLabel>
        <ADatepicker
          onError={this.handleError}
          onInput={this.handleInput}
          id={this.id}
          value={value}
          inputValue={this.state.inputValue}
          className={inputClassName}
          onChange={this.changeValue}
          {...pickerProps}
        />
        {errorMessage ? <AFormatError helperText={errorMessage} /> : null}
      </div>
    )
  }
}

export default withFormsy(DatepickerFormsy)
